const routes = {
  home: '/',
  women: '/?view=women',
  men: '/?view=men',
  kids: '/?view=kids',
  trademark: '/thuong-hieu',
  sale: '/collections/special-deal',
  newArrival: '/collections/new-arrival',
  collections: '/collections',
  search: '/search',
  login: '/tai-khoan/dang-nhap',
  register: '/tai-khoan/dang-ky',
  product: '/san-pham',
  cart: '/gio-hang',
  checkingOrder: '/kiem-tra-don-hang',
  payment: '/thanh-toan',
  account: '/tai-khoan',
  order: '/don-hang',
  address: '/tai-khoan/dia-chi',
  receiveNotify: '/tai-khoan/thong-bao',
  logout: '/logout',
  partner: '/tai-khoan/doi-tac',
  recentlyViewed: '/tai-khoan/da-xem-gan-day',
  changePassword: '/tai-khoan/doi-mat-khau',
  help: '/pages/chinh-sach-bao-mat',
  introduce: '/pages/gioi-thieu-koga',
  comingSoon: '/coming-soon',
};
export default routes;
