

export const BACKGROUND_AVATAR = [
  '#46cf85',
  '#36d0e8',
  '#8f86f3',
  '#fba85a',
  '#f56a00',
  '#0d9394',
  '#ffab1d',
  '#2092ec',
  '#D02ED6',
  '#B24BB6',
  '#12CC44',
  '#32553B',
  '#12B393',
];
