import { cartService } from '@/services';
import { clearCart, setCart } from '@/store/actions';
import { selectAuth } from '@/store/selectors';
import { useAppDispatch, useAppSelector } from '@/types/commons';
import { useEffect } from 'react';
import useSWR from 'swr';

export function useChangeCart() {
  const { user } = useAppSelector(selectAuth);
  const dispatch = useAppDispatch();
  const { data: myCart, error } = useSWR(
    'GET_MY_CART',
    user?._id ? cartService.getMyCart : () => undefined,
    {
      onErrorRetry: (error, key, config, revalidate, { retryCount }) => {
        if (error?.status === 404 && retryCount >= 3) {
          // Xoá cache của request
          revalidate({ retryCount: 0 });
        }
      },
    },
  );
  useEffect(() => {
    if (myCart) {
      dispatch(setCart(myCart.cartItems));
    }
    if (error) {
      dispatch(clearCart());
    }
  }, [myCart, error, dispatch]);
}
