import classNames from 'classnames/bind';
import { memo, useRef } from 'react';
import { useLocation } from 'react-router-dom';

import { CloseIcon, HeartFragileIcon } from '@/components/shares';
import { routes } from '@/config';
import { useCart, useFavorite } from '@/hooks/services';
import { selectAuth } from '@/store/selectors';
import { useAppSelector } from '@/types/commons';
import { StoreCartItem, StoreFavorite } from '@/types/entities';
import { Button, Drawer } from 'antd';
import { EmptyContent } from '../empty-content';
import { RecommendedProduct } from '../recommended-product';
import styles from './WishList.module.scss';
import { FavoriteItem } from '../../carts';
import useMediaQuery from 'beautiful-react-hooks/useMediaQuery';
import { useSWRConfig } from 'swr';

const cx = classNames.bind(styles);

type Props = {
  openWishList: boolean;
  onClose: () => void;
};
const WishList: React.FC<Props> = ({ openWishList, onClose }) => {
  const { mutate } = useSWRConfig()
  const location = useLocation();
  const { onAddToCart } = useCart()
  const { onRemoveFavorite } = useFavorite()
  const { user } = useAppSelector(selectAuth);
  const contentRef = useRef<HTMLDivElement>(null);
  const isTablet = useMediaQuery('(max-width: 68rem)');

  const handleAddAllToCart = async () => {
    if (user?.favorites && user.favorites.length > 0) {
      location.pathname === routes.cart && onClose();

      for (let index = 0; index < user.favorites.length; index++) {
        const favorite = user.favorites[index];
        await Promise.all(
          [
            onRemoveFavorite(favorite.product._id, () => {
              if (index === user.favorites.length - 1) {
                mutate('GET_PROFILE')
              }
            }, () => {

            }, true),
            onAddToCart({
              product: favorite.product,
              color: favorite.color,
              size: favorite.size,
              image: favorite.product.storedProducts[0].colors[0].images[0],
              repository: favorite.product.storedProducts[0].repository._id,
              quantity: favorite.quantity,
            }, () => {
              if (index === user.favorites.length - 1) {
                mutate('GET_MY_CART')
              }
            }, () => {

            },
              true,
              false, true
            )
          ]
        )

      }
    }
  };
  return (

    <Drawer
      title="Yêu thích"
      placement={'right'}
      closable={false}
      onClose={onClose}
      closeIcon={<div onClick={onClose} className={cx('close')}>
        <CloseIcon />
      </div>}
      open={openWishList}
      width={isTablet ? '50vw' : '35vw'}
      key={'right'}
    >
      {user?.favorites && user?.favorites.length > 0 ? (
        <div className='flex flex-col gap-4'>
          <div ref={contentRef} className={cx('content')}>
            {user.favorites.map((favorite: StoreFavorite) => {
              const item: StoreCartItem = {
                _id: favorite._id,
                color: favorite.color,
                image: favorite.product.storedProducts[0].colors[0].images[0],
                product: favorite.product,
                quantity: favorite.quantity,
                size: favorite.size,
                repository: '',
              };
              return (
                <FavoriteItem
                  key={favorite._id}
                  handleClosePopUp={onClose}
                  cartItem={item}
                  isCart={false}
                />
              );
            })}
          </div>
          <div className="flex">
            <Button className='flex-1 uppercase' size="large" type="primary" onClick={handleAddAllToCart}>Chuyển tất cả vào giỏ hàng</Button>
          </div>
        </div>
      ) : (
        <div>
          <EmptyContent
            titleBtn="Dạo một vòng xem nào"
            content1="Không có sản phẩm nào !"
            icon={<HeartFragileIcon width="10rem" height="10rem" />}
            noPadding
          />
          <RecommendedProduct type="wishlist" small line={false} />
        </div>
      )}
    </Drawer>

  );
};

export default memo(WishList);
