import React, { Fragment, Suspense, useEffect, useState } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate, useLocation } from 'react-router-dom';
import { publicRoutes } from './routes';

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Loading } from './components/shares';
import { DefaultLayout } from './layouts';
import { useAppSelector } from './types/commons';
import { selectAuth } from './store/selectors';
import { routes } from './config';
import { ConfigProvider } from 'antd';
import { useDispatch } from 'react-redux';
import { setWidth } from './store/slices/app.slice';

// 258122201888-nki79uqhlu4g7tbmj5cukepcqvcs2d5u.apps.googleusercontent.com
// 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
const App: React.FC = () => {
  const [currentWidth, setCurrentWidth] = useState(window.innerWidth);
  const dispatch = useDispatch();
  const setWidthFunction = () => {
    setCurrentWidth(window.innerWidth);
  };
  useEffect(() => {
    window.addEventListener('resize', setWidthFunction);
    return () => {
      window.removeEventListener('resize', setWidthFunction);
    };
  });
  useEffect(() => {
    if (currentWidth <= 600) dispatch(setWidth(1));
    else if (currentWidth <= 992) dispatch(setWidth(2));
    else dispatch(setWidth(3));
  }, [currentWidth]);
  return (
    <ConfigProvider theme={{ token: { colorPrimary: '#2e2e2e' } }}>
      <Router>
        <ToastContainer autoClose={3000} />
        <Routes>
          {publicRoutes.map((route, index) => {
            const Page = route.component;
            let Layout: any = DefaultLayout;
            if (route.layout) {
              Layout = route.layout;
            } else if (route.layout === null) {
              Layout = Fragment;
            }
            let PrivateRoute = ({ children }: { children: any }) => {
              return children;
            };
            if (route.private) {
              PrivateRoute = RequireAuth;
            }
            return (
              <Route
                key={index}
                path={route.path}
                element={
                  <PrivateRoute>
                    <Layout>
                      <Suspense fallback={<Loading />}>
                        <Page />
                      </Suspense>
                    </Layout>
                  </PrivateRoute>
                }
              />
            );
          })}
        </Routes>
      </Router>
    </ConfigProvider>
  );
};
function RequireAuth({ children }: { children: any }): JSX.Element {
  const { user } = useAppSelector(selectAuth);
  let location = useLocation();
  if (!user) {
    return <Navigate to={routes.login} state={{ from: location }} replace={true} />;
  }
  return children;
}
export default App;
