import { Nullable } from '@/types/commons';
import { StoreConversation } from '@/types/entities';
import { randomBgAvatar } from '@/utils';
import { createSlice, type PayloadAction } from '@reduxjs/toolkit';

type ChatState = {
  selectedConversation: Nullable<StoreConversation>;
  activeChat: boolean;
  colorUser: string;
};

const initialState: ChatState = {
  selectedConversation: null,
  activeChat: false,
  colorUser: randomBgAvatar(),
};
export const chatSlice = createSlice({
  name: 'chat',
  initialState,
  reducers: {
    setActiveChat: (state, action: PayloadAction<ChatState['activeChat']>) => {
      state.activeChat = action.payload;
    },
    toggleActiveChat: (state) => {
      state.activeChat = !state.activeChat;
    },
    setSelectedConversation: (state, action: PayloadAction<ChatState['selectedConversation']>) => {
      state.selectedConversation = action.payload;
    },
    // setContactUser: (state, action: PayloadAction<ChatState["contactUser"]>) => {
    //   state.contactUser = action.payload;
    // },
    // setQuantityUnreadMessage: (
    //   state,
    //   action: PayloadAction<QuantityUnread[]>
    // ) => {
    //   state.quantityUnread = {
    //     data: action.payload,
    //     total: action.payload.reduce((acc, item) => acc + item.total, 0),
    //   };
    // },
    // updateStatusUserForContactUsers: (
    //   state,
    //   action: PayloadAction<StatusPayload>
    // ) => {
    //   const foundIndex = state.contactUser?.membersInGroup?.findIndex(
    //     (item) => item._id === action.payload.userId
    //   );
    //   if (foundIndex && foundIndex !== -1) {
    //     const user = state.contactUser?.membersInGroup[foundIndex];
    //     if (action.payload.status === "online") {
    //       state.contactUser = {
    //         ...state.contactUser,
    //         membersInGroup: [
    //           {
    //             ...user,
    //             status: action.payload.status,
    //           },
    //           ...state.contactUser.membersInGroup.filter(
    //             (item) => item._id !== action.payload.userId
    //           ),
    //         ],
    //       };
    //     } else {
    //       const members = state.contactUser.membersInGroup;
    //       members[foundIndex].status = action.payload.status;
    //       state.contactUser = {
    //         ...state.contactUser,
    //         membersInGroup: members.sort((a,b) => (a.status > b.status ? -1 : 1))
    //       };
    //     }
    //   }
    // },
    clearChat: (state) => {
      state.selectedConversation = null;
      //   state.contactUser = null;
      //   state.quantityUnread = null;
    },
  },
});

export const {
  clearChat,
  setActiveChat,
  //   setContactUser,
  toggleActiveChat,
  setSelectedConversation,
  //   setQuantityUnreadMessage,
  //   updateStatusUserForContactUsers,
} = chatSlice.actions;
export default chatSlice.reducer;
