import { StoreCart, StoreCartItem, StoreProduct } from '@/types/entities';
import { addToCart, decreaseCart, increaseCart, removeFromCart, setCart } from '@/store/actions';
import { handleErrorHooks, randomUniqueID } from '@/utils';
import { useAppDispatch, useAppSelector } from '@/types/commons';
import { useCallback, useState } from 'react';

import { ErrCallbackType } from '../types';
import { cartService } from '@/services';
import { selectAuth } from '@/store/selectors';
import { toast } from 'react-toastify';
import { updateQuantityCart } from '@/store/slices/cart.slice';

type ParamAddToCart = {
  product: StoreProduct;
  color: string;
  size: string;
  image: string;
  quantity: number;
  repository?: string;
};
export function useCart() {
  const [loading, setLoading] = useState(false);
  const { user } = useAppSelector(selectAuth);
  const dispatch = useAppDispatch();
  const handleAddToCart = useCallback(
    async (
      item: ParamAddToCart,
      successCallback: (card: StoreCart) => void,
      errCallback?: ErrCallbackType,
      isLogged?: boolean,
      isToast?: boolean,
      isMultiple?: boolean,
    ) => {
      setLoading(true);
      dispatch(
        addToCart({
          product: item.product,
          color: item.color,
          size: item.size,
          quantity: item.quantity,
          image: item.image,
          repository: item.repository || '',
          _id: randomUniqueID(),
        }),
      );
      // if (user || isLogged) {
      //   try {
      //     const added = await cartService.addToCart({
      //       product: item.product._id,
      //       color: item.color,
      //       size: item.size,
      //       quantity: item.quantity,
      //     });
      //     !isMultiple && dispatch(setCart(added.cartItems));
      //     console.log('added: ', added);
      //     isToast &&
      //     successCallback(added);
      //     return;
      //   } catch (error) {
      //     handleErrorHooks(error, errCallback)
      //   } finally {
      //     setLoading(false);
      //   }
      // } else {

      //   setLoading(false);
      // }
    },
    [user, dispatch],
  );
  const handleRemoveItemCart = useCallback(
    async (cartItem: StoreCartItem, successCallback?: () => void) => {
      try {
        setLoading(true);
        if (user) {
          const res = await cartService.removeItemCart(cartItem._id);
          console.log('res', res);
        }
        successCallback ? successCallback() : dispatch(removeFromCart(cartItem));
      } catch (error) {
      } finally {
        setLoading(false);
      }
    },
    [dispatch, user],
  );

  const handleDecreaseCart = useCallback(
    async (cartItem: StoreCartItem, quantity: number) => {
      if (quantity < 1) {
        dispatch(decreaseCart(cartItem));
      }
    },
    [dispatch, user],
  );
  const handleIncreaseCart = useCallback(
    async (cartItem: StoreCartItem, quantity: number) => {
      dispatch(increaseCart(cartItem));
    },
    [dispatch, user],
  );
  const handleUpdateQuantity = useCallback(
    async (cartItem: StoreCartItem, quantity: number) => {
      dispatch(
        updateQuantityCart({
          cartId: cartItem._id!,
          quantity: quantity,
        }),
      );
    },
    [dispatch, user],
  );
  return {
    loading,
    onAddToCart: handleAddToCart,
    onRemoveItemCart: handleRemoveItemCart,
    onDecreaseCart: handleDecreaseCart,
    onIncreaseCart: handleIncreaseCart,
    onUpdateQuantity: handleUpdateQuantity,
  };
}
