import { AppStoreImage, GooglePlayImage } from '@/components/shares';
import { Breadcrumb, Col, Layout, Menu, Row, theme } from 'antd';

import { Link } from 'react-router-dom';
import classNames from 'classnames/bind';
import { colLinks } from '@/assets/colLinks';
import { memo } from 'react';
import styles from './FooterMain.module.scss';

const { Header, Content, Footer } = Layout;

const cx = classNames.bind(styles);
function FooterMain() {
  return (
    <>
      {/* <Footer style={{ textAlign: 'center' }}>
        Ant Design ©{new Date().getFullYear()} Created by Ant UED
      </Footer> */}
      <footer className={cx('wrapper')}>
        <div className={cx('container')}>
          <span className={cx('footer-main_copy-right')}>Hotline: 0869 013 520</span>
          <div className={cx('footer-main_copy-right')}>
            <p>© Copyright 2022 HPT CORPORATION. All rights reserved.</p>
          </div>
        </div>
      </footer>

      {/* <footer className="bg-white lg:grid lg:grid-cols-5 w-full mt-10">
        <div className="px-4 py-16 sm:px-6 lg:col-span-3 lg:px-8 border-t border-gray-100">
          <div className="grid grid-cols-1 gap-8 sm:grid-cols-2">
            <div>
              <p>
                <span className="text-lg uppercase tracking-wide text-gray-500"> Call us </span>
                <a
                  href="#"
                  className="block text-2xl font-medium text-gray-900 hover:opacity-75 sm:text-3xl"
                >
                  0869013520
                </a>
              </p>
              <ul className="mt-8 space-y-1 text-xl text-gray-700">
                <li>Hoạt động: Thứ 2 đến thứ 6</li>
                <li>Thời gian: 9 giờ sáng - 5 giờ chiều</li>
              </ul>
            </div>
          </div>
          <div className="mt-12 border-t border-gray-100 pt-12">
            <div className="sm:flex sm:items-center sm:justify-between">
              <p className="mt-8 text-lg text-gray-500 sm:mt-0">
                &copy; Copyright 2024 HPT CORPORATION. All rights reserved.
              </p>
            </div>
          </div>
        </div>
        <div className="relative block h-32 lg:col-span-2 lg:h-full">
          <img
            src="/images/footer.jpg"
            alt=""
            className="absolute inset-0 h-full w-full object-cover"
          />
        </div>
      </footer> */}
    </>
  );
}

export default memo(FooterMain);
