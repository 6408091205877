// Layouts

// Pages

import React, { ReactNode, lazy } from 'react';

import { HeaderOnly } from '@/layouts';
import { routes } from '@/config';

type routeType = {
  path: string;
  component: React.FC<any>;
  layout?: React.FC<{ children: ReactNode }> | null;
  private?: boolean;
};
// public routes
const publicRoutes: Array<routeType> = [
  {
    path: routes.home,
    component: lazy(() => import('@/pages/Collection')),
  },
  {
    path: `${routes.product}/:slug`,
    component: lazy(() => import('@/pages/Product')),
  },

  {
    path: routes.receiveNotify,
    component: lazy(() => import('@/pages/Account')),
  },
  {
    path: routes.help,
    component: lazy(() => import('@/pages/Policy')),
    layout: HeaderOnly,
  },
  {
    path: routes.introduce,
    component: lazy(() => import('@/pages/Introduce')),
    layout: HeaderOnly,
  },
  {
    path: routes.partner,
    component: lazy(() => import('@/pages/Account')),
  },
  {
    path: routes.cart,
    component: lazy(() => import('@/pages/Cart')),
    layout: HeaderOnly,
  },
  {
    path: routes.payment,
    component: lazy(() => import('@/pages/Payment')),
    layout: null,
  },
  {
    path: routes.login,
    component: lazy(() => import('@/pages/Login')),
    layout: null,
  },
  {
    path: routes.register,
    component: lazy(() => import('@/pages/Login')),
    layout: null,
  },
  {
    path: `${routes.collections}/:slugCollection`,
    component: lazy(() => import('@/pages/Collection')),
  },
  {
    path: routes.search,
    component: lazy(() => import('@/pages/Search')),
  },
  {
    path: routes.account,
    component: lazy(() => import('@/pages/Account')),
  },
  {
    path: routes.order,
    component: lazy(() => import('@/pages/Account')),
  },
  {
    path: `${routes.order}/:orderID`,
    component: lazy(() => import('@/pages/Account')),
  },
  {
    path: routes.address,
    component: lazy(() => import('@/pages/Account')),
  },  
  {
    path: routes.comingSoon,
    component: lazy(() => import('@/pages/ComingSoon')),
  },
  {
    path: routes.recentlyViewed,
    component: lazy(() => import('@/pages/Account')),
  },
  {
    path: '*',
    component: lazy(() => import('@/pages/NoPageFound')),
  },
];
const privateRoutes: Array<routeType> = [];
export { publicRoutes, privateRoutes };
