import { Footer, Header, ScrollToTop } from '../components';
import React, { ReactNode, useEffect } from 'react';

import Chat from '@/pages/chat/Chat';
import classNames from 'classnames/bind';
import { selectAuth } from '@/store/slices/auth.slice';
import { socketServices } from '@/services';
import styles from './DefaultLayout.module.scss';
import { useAppSelector } from '@/types/commons';
import { useLocation } from 'react-router-dom';

const cx = classNames.bind(styles);

type Props = {
  children: ReactNode;
};
const DefaultLayout: React.FC<Props> = ({ children }) => {
  const { pathname } = useLocation();
  const { user } = useAppSelector(selectAuth);
  const useQuery = () => new URLSearchParams(useLocation().search);
  let query = useQuery();
  useEffect(() => {
    const handleScrollTop = () => {
      document.body.scrollIntoView({ behavior: 'smooth', block: 'start' });
    };
    handleScrollTop();
  }, [pathname]);
  useEffect(() => {
    const handleScrollTop = () => {
      document.body.scrollIntoView({ behavior: 'smooth', block: 'start' });
    };
    handleScrollTop();
  }, [pathname, query]);
  useEffect(() => {
    if (user?._id) {
      socketServices.emit('connection', { user_id: user._id });
    }
    socketServices.listen('emit-connection', ({ message }) => {
      console.log('OOOOOOKKKK: ', message);
    });
  }, [user?._id]);
  return (
    <div className="flex flex-col items-center">
      {/* Header */}
      <ScrollToTop />
      {/* <Chat /> */}
      <Header />
      <div className="w-full flex">
        <div className="flex-1"> {children} </div>
      </div>
      <Footer />
    </div>
  );
};
export default DefaultLayout;
