import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { RootState } from '@/store/store';
import { StoreProduct } from '@/types/entities';

type AppState = {
  width: number;
};
const initialState: AppState = {
  width: 4,
  // 4 : pc screen;
  // 3 : tablet screen;
  // 2 : small tablet screen;
  // 1 : mobile screen;
};
export const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    setWidth: (state, action) => {
      state.width = action.payload;
    },
  },
});

// export const selectViewedProduct = (state: RootState) => state.product.viewed;

// export const {setViewedProduct} = productSlice.actions;

export default appSlice.reducer;
export const { setWidth } = appSlice.actions;
