import { userService } from '@/services';
import { updateProfile } from '@/store/actions';
import { selectAuth } from '@/store/selectors';
import { useAppDispatch, useAppSelector } from '@/types/commons';
import { useEffect } from 'react';
import useSWR from 'swr';

export function useChangeProfile() {
  const dispatch = useAppDispatch();
  const { user } = useAppSelector(selectAuth);
  const { data } = useSWR('GET_PROFILE', user?._id ? userService.getProfile : () => undefined);
  useEffect(() => {
    if (data) {
      dispatch(updateProfile(data));
    }
  }, [data, dispatch]);
}
