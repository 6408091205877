import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import './ProductSlider.scss';

import React, { memo } from 'react';

import { Carousel } from 'antd';
import { NextArrow, PrevArrow } from '@/components/shares';
import { ProductItem } from '../product-item';
import { StoreProduct } from '@/types/entities';
import useMediaQuery from 'beautiful-react-hooks/useMediaQuery';

type SlideProductProps = {
  products: StoreProduct[];
  title?: string;
  type?: 'wishlist' | 'common';
  showBtn?: boolean;
};
const SlideProduct: React.FC<SlideProductProps> = ({ products, title, type = 'common' }) => {
  const isTablet = useMediaQuery('(max-width: 68rem)');
  const isMobile = useMediaQuery('(max-width: 40rem)');
  console.log({ isMobile, isTablet });

  const onChange = (currentSlide: number) => {
    console.log(currentSlide);
  };
  return (
    <div className="my-12 p-8 rounded-md border shadow-lg relative">
      {title && <h4 className="uppercase text-[#868d95] mb-8 font-bold text-2xl">{title}</h4>}
      <div className="bg-card h-1 mt-[2rem] mb-8"></div>

      <Carousel
        afterChange={onChange}
        className="flex gap-4"
        autoplay
        slidesPerRow={type === 'common' ? (isMobile ? 2 : isTablet ? 3 : 5) : 2}
        swipeToSlide
        arrows
        draggable
        slidesToShow={1}
        slidesToScroll={1}
        nextArrow={<NextArrow />}
        prevArrow={<PrevArrow />}
      >
        {products?.map((product) => (
          <div key={product?._id}>
            <ProductItem product={product} />
          </div>
        ))}
      </Carousel>
    </div>
  );
};

export default memo(SlideProduct);
