import { productService } from '@/services';
import classNames from 'classnames/bind';
import { memo } from 'react';
import useSWR from 'swr';
import { SlideProduct } from '../slide-product';
import styles from './RecommendedProduct.module.scss';
const cx = classNames.bind(styles);
type Props = {
  line?: boolean;
  small?: boolean;
  noMargin?: boolean;
  type?: 'common' | 'wishlist'
};
const RecommendedProduct: React.FC<Props> = ({
  line = true,
  small = false,
  noMargin,
  type = 'common'
}) => {
  const { data: productsRecommended } = useSWR(`RECOMMEND_PRODUCT`, () => productService.getAll({
    page: 1,
    limit: 20,
    offset: 0,
  }))
  return (
    <div>
      <div className={cx('product-recommended')}>
        {line && <div className={cx('line')}></div>}
        <div className={cx('container-fluid', small && 'container-slide', noMargin && 'mr-0')}>
          <SlideProduct
            type={type}
            title="Gợi ý cho bạn"
            products={productsRecommended?.docs || []}
          />
        </div>
      </div>
    </div>
  );
};

export default memo(RecommendedProduct);
