import { orderService } from '@/services';
import { StoreOrder } from '@/types/entities';
import { useCallback, useState } from 'react';
import { ErrCallbackType } from '../types';
import { ParamCancelOrder, ParamCreateOrder } from '@/services/types';
import { handleErrorHooks } from '@/utils';
import { useSWRConfig } from 'swr';

export function useOrder() {
  const {mutate} = useSWRConfig()
  const [loading, setLoading] = useState(false);
  const handleCreateOrder = useCallback(async (
    params: ParamCreateOrder,
    successCallback: (order: StoreOrder) => void,
    errorCallback: ErrCallbackType
  ) => {
    try {
      setLoading(true);
      const ordered =  await orderService.create(params)
      successCallback(ordered);
    } catch (error) {
        handleErrorHooks(error, errorCallback)
    } finally {
      setLoading(false);
    }
  }, []);
  const handleCancelOrder = useCallback(async (
    id: StoreOrder['_id'],
    params: ParamCancelOrder,
    successCallback: () => void,
    errorCallback: ErrCallbackType
  ) => {
    try {
      setLoading(true);
      const canceled =  await orderService.cancelOrder(id, params)
      mutate(`OrderDetails${canceled._id}`)
      successCallback();
    } catch (error) {
        handleErrorHooks(error, errorCallback)
    } finally {
      setLoading(false);
    }
  }, [mutate]);
  return {
    loading,
    onCreateOrder: handleCreateOrder,
    onCancelOrder: handleCancelOrder,
  };
}
