import axiosClient from '@/lib/axios';
import { ParamCreateReview, QueryOptions } from './types';
import { ResponseMessage, ResponsePaginate } from '@/types/commons';
import { StoreReview } from '@/types/entities';

const URL = 'reviews';
const reviewService = {
  create: async (reviews: ParamCreateReview[]): Promise<ResponseMessage> =>
    axiosClient.post(URL, { reviews }),
  getReviewsByProduct: async (
    productId: string,
    query: QueryOptions,
  ): Promise<ResponsePaginate<StoreReview>> =>
    axiosClient.get(`${URL}/product/${productId}`, { params: query }),
};
export default reviewService;
