import React from 'react'
import ReactLoading from 'react-loading'

type LoadingButtonProps = {
    variant?: 'light' | 'dark'
}
const LoadingButton = ({ variant = 'dark' }: LoadingButtonProps) => {
    return (
        <ReactLoading
            type="spinningBubbles"
            color={variant === 'dark' ? "#2e2e2e" : '#f7f8f9'}
            width={20}
            height={20}
        />
    )
}

export default LoadingButton