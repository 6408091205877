import {
  Button,
  CloseIcon,
  Dialog,
  LoadingButton,
  MinusIcon,
  PlusStrongIcon,
} from '@/components/shares';
import { routes } from '@/config';
import { useDebounce } from '@/hooks';
import { useCart, useFavorite } from '@/hooks/services';
import { decreaseCart, increaseCart, removeFromCart } from '@/store/actions';
import { selectAuth, selectCart } from '@/store/selectors';
import { useAppDispatch, useAppSelector } from '@/types/commons';
import { StoreCartItem } from '@/types/entities';
import { CartAction } from '@/types/unions';
import { Input } from 'antd';
import classNames from 'classnames/bind';
import React, { memo, useCallback, useEffect, useState } from 'react';
import ReactLoading from 'react-loading';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { mutate } from 'swr';
import styles from './FavoriteItem.module.scss';
const cx = classNames.bind(styles);
type FavoriteItemProps = {
  cartItem: StoreCartItem;
  handleClosePopUp?: () => void;
  isCart?: boolean;
};
let isLoadingRemove = false;
const FavoriteItem: React.FC<FavoriteItemProps> = ({
  cartItem,
  handleClosePopUp = () => {},
  isCart = true,
}) => {
  const location = useLocation();
  const dispatch = useAppDispatch();
  const { user } = useAppSelector(selectAuth);
  const cart = useAppSelector(selectCart);
  const navigate = useNavigate();
  const [isOpenDialog, setIsOpenDialog] = useState(false);
  const [defaultSize, setDefaultSize] = useState<string>(cartItem.size);
  const [valueQuantity, setValueQuantity] = useState(cartItem.quantity!);

  const valueDebounce = useDebounce(valueQuantity.toString(), 200);

  const {
    loading: loadingCart,
    onAddToCart,
    onRemoveItemCart,
    onDecreaseCart,
    onIncreaseCart,
    onUpdateQuantity,
  } = useCart();
  const { loading, onRemoveFavorite, onAddFavorite } = useFavorite();

  const handleAddToCart = async () => {
    if (!isCart) {
      isLoadingRemove = true;
      await Promise.all([
        onAddToCart(
          {
            product: cartItem.product,
            color: cartItem.color,
            size: defaultSize,
            image: cartItem.image,
            repository: cartItem.repository,
            quantity: 1,
          },
          () => {
            mutate('GET_MY_CART');
          },
          () => {},
          true,
          false,
          true,
        ),
        onRemoveFavorite(
          cartItem.product._id,
          () => {
            isLoadingRemove = false;
          },
          () => {},
        ),
      ]);
    } else {
      isLoadingRemove = true;
      await Promise.all([
        onRemoveItemCart(cartItem),
        onAddFavorite(cartItem.product._id, () => {}),
      ]);
    }
  };
  useEffect(() => {
    if (Number(valueDebounce) > 0) {
      onUpdateQuantity(cartItem, Number(valueDebounce));
    }
  }, [valueDebounce]);

  const handleCart = async (action: CartAction) => {
    if (user && cartItem) {
      switch (action) {
        case 'add':
          handleAddToCart();
          break;
        case 'decrease':
          onDecreaseCart(cartItem, cartItem.quantity - 1);
          break;

        case 'increase':
          onIncreaseCart(cartItem, cartItem.quantity + 1);
          break;
        case 'remove':
          if (isCart) {
            onRemoveItemCart(cartItem);
          } else {
            onRemoveFavorite(
              cartItem.product._id,
              () => {
                isLoadingRemove = false;
              },
              () => {},
            );
          }
          break;
        default:
          break;
      }
    } else {
      // action === 'add' && navigate(routes.login, { state: { from: location } });
      action === 'decrease' && dispatch(decreaseCart(cartItem));
      action === 'increase' && dispatch(increaseCart(cartItem));
      action === 'remove' && dispatch(removeFromCart(cartItem));
    }
  };

  const handleChangeSelectedSize = useCallback((value: string) => {
    setDefaultSize(value);
  }, []);
  console.log(cartItem);
  if (!cartItem.product) return null;

  return (
    <div className={cx('wrapper')}>
      {/* {(isLoadingUpdated || isLoadingRemoved) && <Loading />} */}
      <div className={cx('container')}>
        <Link to={routes.product + '/' + cartItem.product?.slug} className={cx('image-wrapper')}>
          <img src={process.env.REACT_APP_API_URL + cartItem.image} alt="" />
        </Link>
        <div className={cx('text-wrapper')}>
          <div className={cx('top')}>
            <div onClick={() => setIsOpenDialog(true)} className={cx('close')}>
              <CloseIcon />
            </div>
            <Dialog
              title="Xóa sản phẩm?"
              description={`Bạn chắc chắn muốn xóa sản phẩm này khỏi ${
                isCart ? 'Giỏ Hàng' : 'danh sách Yêu Thích'
              }?`}
              isOpenDialog={isOpenDialog}
              handleCloseDialog={() => setIsOpenDialog(false)}
            >
              {/* <Button onClick={() => handleCart('add')} border small primary>
                {loadingCart || loading ? (
                  <LoadingButton variant="light" />
                ) : isCart ? (
                  'Chuyển vào Yêu Thích'
                ) : (
                  'Chuyển vào giỏ hàng'
                )}
              </Button> */}
              <Button onClick={() => handleCart('remove')} small className="text-primary">
                {isLoadingRemove && !isCart && loading ? <LoadingButton /> : 'Xóa'}
              </Button>
            </Dialog>
          </div>

          <div className={cx('product-price', { hasSale: cartItem.product.discount > 0 })}>
            <span className={cx('price')}>
              {(
                cartItem.product.price -
                cartItem.product.price * (cartItem.product.discount / 100)
              ).toLocaleString('vn-VN')}
              ₫
            </span>
            <del>{cartItem.product.price.toLocaleString('vn-VN')}₫</del>
          </div>

          {isCart ? (
            <div className={cx('product-quantity')}>
              <div className={cx('input-number')}>
                {/* <div onClick={() => handleCart('decrease')} className={cx('btn-quantity')}>
                  <MinusIcon />
                </div> */}
                <Input
                  value={valueQuantity}
                  onChange={(e) =>
                    setValueQuantity((prev) => {
                      const newQuantity = Number(e.target.value);
                      if (newQuantity > 0) return newQuantity;
                      return prev;
                    })
                  }
                  size="large"
                  variant="borderless"
                  className="max-w-[10rem]"
                />
                {/* <div onClick={() => handleCart('increase')} className={cx('btn-quantity')}>
                  <PlusStrongIcon />
                </div> */}
              </div>
            </div>
          ) : (
            <div className={cx('product-actions')}>
              {cart.cartItems.find(
                (item: StoreCartItem) =>
                  item.product._id === cartItem.product._id && item.color === cartItem.color,
              ) ? (
                <Button disabled small primary children="Đã có trong giỏ hàng" />
              ) : (
                <Button onClick={() => handleCart('add')} small primary>
                  {loading && loadingCart && isLoadingRemove ? (
                    <ReactLoading type="spinningBubbles" color="#ffffff" width={20} height={20} />
                  ) : (
                    'Chuyển vào giỏ hàng'
                  )}
                </Button>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default memo(FavoriteItem);
