import axiosClient from '@/lib/axios';
import { ResponsePaginate } from '@/types/commons';
import { StoreOrder } from '@/types/entities';
import { ParamCancelOrder, ParamCreateOrder, QueryOptions } from './types';

const URL = 'orders';
const orderService = {
  getMyOrder: async (
    params: QueryOptions & { status: 'Processing' | 'Completed' },
  ): Promise<ResponsePaginate<StoreOrder>> => axiosClient.get(`${URL}/me`, { params }),
  create: async (body: ParamCreateOrder): Promise<StoreOrder> => axiosClient.post(URL, body),
  getById: async (id: StoreOrder['_id']): Promise<StoreOrder> => axiosClient.get(`${URL}/${id}`),
  cancelOrder: async (id: StoreOrder['_id'], body: ParamCancelOrder): Promise<StoreOrder> =>
    axiosClient.patch(`${URL}/${id}/cancel`, body),
  getOrderByNumberPhone: async (
    params: QueryOptions & { numberPhone?: string; status: 'Processing' | 'Completed' },
  ): Promise<ResponsePaginate<StoreOrder>> =>
    axiosClient.get(`${URL}/numberPhone`, {
      params,
    }),
};
export default orderService;
