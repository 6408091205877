import axiosClient from '@/lib/axios';
import { FileResponse } from '@/types/commons';
import { FolderUpload } from './types';

const URL = 'uploads';
const uploadService = {
  single: (formData: FormData, folder: FolderUpload): Promise<FileResponse> =>
    axiosClient.post(`${URL}/single`, formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
      params: {
        folder,
      },
    }),
  multiple: async (formData: FormData, folder: FolderUpload): Promise<string[]> =>
    axiosClient.post(`${URL}/multiple`, formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
      params: {
        folder,
      },
    }),
};
export default uploadService;
