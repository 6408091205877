import './style.css';

import { Button, Card, Flex, Tag } from 'antd';
import { HeartActiveIcon, HeartIcon } from '@/components/shares';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { StoreColor, useAppSelector } from '@/types/commons';
import { memo, useMemo, useState } from 'react';
import { useCart, useFavorite } from '@/hooks/services';

import { ShoppingCartOutlined } from '@ant-design/icons';
import { StoreProduct } from '@/types/entities';
import classNames from 'classnames/bind';
import { routes } from '@/config';
import { selectAuth } from '@/store/selectors';
import styles from './ProductItem.module.scss';
import { toast } from 'react-toastify';

const { Meta } = Card;
// import useSWR from 'swr';

const cx = classNames.bind(styles);
type Props = {
  product: StoreProduct;
};
const ProductItem: React.FC<Props> = ({ product }) => {
  const location = useLocation();
  const { user } = useAppSelector(selectAuth);
  const navigate = useNavigate();
  const { loading, onAddFavorite, onRemoveFavorite } = useFavorite();
  const [isCached, setIsCached] = useState<boolean>(false);
  const [defaultImages, setDefaultImages] = useState<StoreColor>(
    product.storedProducts[0].colors[0],
  );
  const [colorActive, setColorActive] = useState<StoreColor>(product.storedProducts[0].colors[0]);

  // const { data } = useSWR(`ProductDetails${product._id}`)
  const handleFavorite = () => {
    if (!user) navigate(routes.login, { state: { from: location } });
    else {
      if (
        user?.favorites.find(
          (item) => typeof item.product === 'string' && item.product === product?._id,
        )
      ) {
        // remove
        onRemoveFavorite(
          product._id,
          () => {
            console.log('Xoá yt');
          },
          ({ message }) => {
            console.log(message);
          },
        );
      } else {
        onAddFavorite(
          product._id,
          () => {
            console.log('Xoá yt');
          },
          ({ message }) => {
            console.log(message);
          },
        );
      }
    }
  };
  const handleOnMouse = (direction: string) => {
    direction === 'mouseover' ? setIsCached(true) : setIsCached(false);
  };
  const handleMouseColor = (color: StoreColor, action: string) => {
    const prevImageDefault: StoreColor | undefined = product.storedProducts[0].colors.find(
      (color: StoreColor) => color._id === colorActive._id,
    );
    action === 'mouseover'
      ? setDefaultImages(color)
      : setDefaultImages((prev: StoreColor) => (prevImageDefault ? prevImageDefault : prev));
  };

  const hearted = useMemo(() => {
    return user && user.favorites.find((item) => item.product._id === product._id);
  }, [product, user]);

  const { onAddToCart, loading: loadingCart } = useCart();

  const handleAddToCart = (successCallback?: () => void) => {
    const { storedProducts } = product;
    const defaultColor = storedProducts[0].colors[0];
    if (product) {
      onAddToCart(
        {
          product: product,
          color: defaultColor._id,
          size: defaultColor.sizes[0].size,
          image: defaultColor.images[0],
          repository: product.storedProducts[0].repository._id,
          quantity: 1,
        },
        () => {
          successCallback && successCallback();
        },
        () => {},
        !!user,
        true,
        false,
      );
    } else {
      toast.warn('Vui lòng chọn màu sắc và kích cỡ !');
    }
  };

  console.log(product);
  return (
    <>
      <Card
        hoverable
        cover={
          <div style={{ overflow: 'hidden', height: '200px' }}>
            <img
              style={{ height: '100%', width: '100%' }}
              alt="example"
              src={process.env.REACT_APP_API_URL + defaultImages?.images[0]}
            />
          </div>
        }
      >
        <Link to={routes.product + '/' + product.slug} state={{ colorSelected: colorActive }}>
          <div
            style={{ height: '45px' }}
            className="hover:text-red-600 font-semibold text-2xl text-gray-800 overflow-hidden text-ellipsis line-clamp-2"
          >
            {product.name}
          </div>
        </Link>
        <span className={cx('price', product.discount > 0 && 'hasSale')}>
          {(product.price - product.price * (product.discount / 100)).toLocaleString('vn-VN')}
          <span className="underline">đ</span>
        </span>
        &nbsp;
        {product?.discount > 0 && (
          <del className="text-base">
            {product.price.toLocaleString('vn-VN')}
            <span className="underline">đ</span>
          </del>
        )}
        &nbsp;
        {product?.discount > 0 && (
          <Tag color="volcano" className="text-base mt-4 mb-2 inline">
            Giảm {product.discount}%
          </Tag>
        )}
        <hr className="h-px my-4 bg-gray-200 border-0" />
        <Flex gap="small" className="pt-3 justify-between items-center">
          <Button
            className="flex justify-center items-center flex-grow "
            onClick={() => handleAddToCart()}
            disabled={!product.category.active}
          >
            <ShoppingCartOutlined style={{ fontSize: '18px' }} />
          </Button>
          <Button
            type="primary"
            size="middle"
            disabled={!product.category.active}
            onClick={() => {
              handleAddToCart();
              navigate(routes.cart);
            }}
          >
            Mua ngay
          </Button>
        </Flex>
      </Card>
      {/* <div className={cx('container')}>
        <div className={cx('wrapper')}>
          <div className={cx('product-img')}>
            <Link
              className={cx('img-link')}
              onMouseOver={() => handleOnMouse('mouseover')}
              onMouseOut={() => handleOnMouse('mouseout')}
              to={routes.product + '/' + product.slug}
              state={{ colorSelected: colorActive }}
            >
               <div style={{ overflow: "hidden", height: "200px" }}>
              <img style={{ height: "100%" }} src={process.env.REACT_APP_API_URL + defaultImages?.images[0]} alt="" />
              <img
              style={{ height: "100%" }}
                src={process.env.REACT_APP_API_URL + defaultImages?.images[1]}
                alt=""
                className={cx('lazyloaded', isCached && 'is-cached')}
              />
              </div>
            </Link>
            {product?.discount > 0 && <div className={cx('discount')}>-{product.discount}%</div>}
            <div onClick={handleFavorite} className={cx('wishlist', hearted && 'active')}>
              <HeartIcon className={cx('heart')} stroke="#ffffff" />
              <HeartActiveIcon className={cx('heart-active')} />
            </div>
            <div className={cx('banner-product')}>
              <img
                src="https://file.hstatic.net/1000284478/file/frame__22__c3a6e45e3424441da3746870f1e77536.png"
                alt=""
                className={cx('banner-img')}
              />
            </div>
            <div className={cx('outer-product-color')}>
              {product.storedProducts[0].colors.map((color) => (
                <div
                  onMouseOver={() => handleMouseColor(color, 'mouseover')}
                  onMouseOut={() => handleMouseColor(color, 'mouseout')}
                  onClick={() => setColorActive(color)}
                  key={color._id}
                  className={cx('product-color-loop', colorActive._id === color._id && 'active')}
                >
                  <img
                    src={process.env.REACT_APP_API_URL + color.imageSmall}
                    alt=""
                    className={cx('product-color-img')}
                  />
                </div>
              ))}
            </div>
          </div>
          <div className={cx('product-loop-info')}>
            <Link to={routes.product + '/' + product.slug} state={{ colorSelected: colorActive }}>
              <h3 className={cx('trademark-name')}>{product.name}</h3>
           
            </Link>
            <div className={cx('product-price', 'flex flex-wrap gap-4 items-center')}>
              <span className={cx('price', product.discount > 0 && 'hasSale')}>
                {(product.price - product.price * (product.discount / 100)).toLocaleString('vn-VN')}
                <span className="underline">đ</span>
              </span>
              {product?.discount > 0 && (
                <del>
                  {product.price.toLocaleString('vn-VN')}
                  <span className="underline">đ</span>
                </del>
              )}
            </div>
            <Flex gap="small">
              <Button size="large">Thêm vào giỏ hàng</Button>
              <Button type="primary" size="large">
                Mua ngay
              </Button>
            </Flex>
          </div>
        </div>
      </div> */}
    </>
  );
};
export default memo(ProductItem);
