import 'tippy.js/dist/tippy.css';

import {
  BagActiveIcon,
  BagIcon,
  Button,
  HeartActiveIcon,
  HeartIcon,
  LogoIcon,
  MenuIcon,
  Popper,
  SearchIcon,
  UserActiveIcon,
  UserIcon,
} from '@/components/shares';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { MenuMobile, MenuSub, Search } from './components';
import { SideBarItem, sidebars } from '@/assets/sidebars';
import { memo, useEffect, useRef, useState } from 'react';
import { selectAuth, selectCart } from '@/store/selectors';
import { useAppDispatch, useAppSelector } from '@/types/commons';
import { useChangeCart, useChangeProfile } from '@/hooks/carts';

import HeadlessTippy from '@tippyjs/react/headless';
import { PopUp } from '@/components/shares/dialogs/popup';
import Tippy from '@tippyjs/react';
import WishList from '@/components/app/products/WishList';
import classNames from 'classnames/bind';
import { headerLinks } from '@/assets/headerLinks';
import { isMobile } from 'react-device-detect';
import { routes } from '@/config';
import styles from './Header.module.scss';
import { updateProfile } from '@/store/actions';
import { useAuth } from '@/hooks/services';
import useSWR from 'swr';
import { useToggle } from '@/hooks';
import userService from '@/services/user.service';

const cx = classNames.bind(styles);

function Header() {
  const useQuery = () => new URLSearchParams(useLocation().search);

  const { handleLogout: onLogout } = useAuth();

  let query: URLSearchParams = useQuery();
  const { user } = useAppSelector(selectAuth);
  let cart = useAppSelector(selectCart);
  useChangeProfile();
  // useChangeCart();
  const [isScrollUp, setIsScrollUp] = useState(false);
  const [isScrollDown, setIsScrollDown] = useState(false);
  const {
    isOpen: activeProfile,
    toggle: toggleProfile,
    onClose: onCloseProfile,
  } = useToggle(false);
  const {
    isOpen: activeWishList,
    toggle: toggleWishList,
    onClose: onCloseWithList,
  } = useToggle(false);
  const [openSearch, setOpenSearch] = useState(false);
  const [currentPageYOffset, setCurrentPageYOffset] = useState<number>(0);
  const [openMenuMobile, setOpenMenuMobile] = useState(false);
  const dispatch = useAppDispatch();

  const headerRef = useRef<HTMLDivElement>(null);

  const location = useLocation();

  const navigate = useNavigate();

  const { data } = useSWR('GET_PROFILE', user?._id ? userService.getProfile : () => undefined);
  useEffect(() => {
    if (data) {
      dispatch(updateProfile(data));
    }
  }, [data, dispatch]);

  useEffect(() => {
    const onScroll: EventListener = (e: any) => {
      onCloseWithList();
      setCurrentPageYOffset(e.target?.documentElement.scrollTop);
      setIsScrollUp(
        e.target?.documentElement.scrollTop >= currentPageYOffset ||
          e.target?.documentElement.scrollTop < 97
          ? false
          : true,
      );
      setIsScrollDown(e.target?.documentElement.scrollTop < currentPageYOffset ? false : true);
      e.target?.documentElement.scrollTop < 97 &&
        headerRef.current?.classList.remove(cx('out-top'));
    };
    window.addEventListener('scroll', onScroll);
    return () => window.removeEventListener('scroll', onScroll);
  }, [currentPageYOffset, onCloseWithList]);

  useEffect(() => {
    if (location.pathname === routes.cart) {
      onCloseProfile();
    }
    onCloseWithList();
    document.title = 'Uni HPT Shop';
  }, [location.pathname, onCloseProfile, onCloseWithList]);

  const handleScrollTop = () => {
    document.body.scrollIntoView({ behavior: 'smooth', block: 'start' });
  };

  const handleClickActive = (direction: string) => {
    if (direction === 'account') {
      toggleProfile();
      onCloseWithList();
    } else if (direction === 'wishlist') {
      if (!user) navigate(routes.login, { state: { from: location } });
      toggleWishList();
      onCloseProfile();
    }
  };

  const handleLogout = async (sidebar: SideBarItem) => {
    onCloseProfile();
    if (sidebar.to === '#') {
      onLogout();
    }
  };

  return (
    <div
      ref={headerRef}
      className={cx(
        'container',
        isMobile ? 'sticky' : isScrollDown && 'out-top',
        isScrollUp && 'sticky',
      )}
    >
      {/* banner */}

      <header className={cx('wrapper')}>
        <div className={cx('inner')}>
          {isMobile && (
            <PopUp
              trigger={
                <div onClick={() => setOpenMenuMobile(true)}>
                  <MenuIcon />
                </div>
              }
              isOpen={openMenuMobile}
              handleClose={() => setOpenMenuMobile(false)}
              position="left"
            >
              <MenuMobile isOpen={openMenuMobile} onClose={() => setOpenMenuMobile(false)} />
            </PopUp>
          )}
          <div className={cx('nav-left')}>
            <Link to={routes.home} className={cx('logo')}>
              <LogoIcon height="40" />
            </Link>
          </div>
          <div className={cx('nav-center')}>
            <ul>
              {headerLinks.map((link, i) => (
                <HeadlessTippy
                  key={i}
                  delay={[0, 10]}
                  interactive={true}
                  placement="bottom"
                  render={(attrs) => {
                    return (
                      <div tabIndex={-1} {...attrs}>
                        <MenuSub subLinks={link.subLinks} />
                      </div>
                    );
                  }}
                >
                  <li className={cx(link.class)}>
                    <Link onClick={() => handleScrollTop()} to={link.to}>
                      {link.title}
                    </Link>
                  </li>
                </HeadlessTippy>
              ))}
            </ul>
          </div>
          <div className={cx('nav-right')}>
            <div onClick={() => setOpenSearch(true)} className={cx('search')}>
              <SearchIcon width="2rem" height="2rem" />
              <span>{query.get('q') || 'Tìm kiếm'}</span>
            </div>
            {openSearch && <Search handleClose={() => setOpenSearch(false)} />}
            {isMobile && (
              <div className={cx('icons')}>
                <SearchIcon width="2.4rem" height="2.4rem" />
              </div>
            )}
            <div className={cx('profile')}>
              <HeadlessTippy
                delay={[0, 50]}
                interactive={true}
                visible={activeProfile}
                onClickOutside={onCloseProfile}
                placement="bottom"
                render={(attrs) => {
                  return user ? (
                    <Popper className="popper-user">
                      <ul className={cx('popper-user')} tabIndex={-1} {...attrs}>
                        {sidebars.map((sidebar, index) => (
                          <li onClick={() => handleLogout(sidebar)} key={index}>
                            <Link to={sidebar.to}>{sidebar.title}</Link>
                          </li>
                        ))}
                      </ul>
                    </Popper>
                  ) : (
                    <Popper className="popper-user">
                      <ul className={cx('popper-user')} tabIndex={-1} {...attrs}>
                        <li>
                          <Link to={routes.help}>Trợ giúp</Link>
                        </li>
                        <li>
                          <Link to={routes.partner}>Trở thành đối tác B2B</Link>
                        </li>
                      </ul>
                      <div className={cx('buttons')}>
                        <Button primary to={routes.login}>
                          Đăng Nhập
                        </Button>
                      </div>
                      <div className={cx('buttons')}>
                        <Button primary to={routes.register}>
                          Tạo Tài Khoản
                        </Button>
                      </div>
                    </Popper>
                  );
                }}
              >
                <Tippy
                  placement="bottom"
                  delay={100}
                  content={
                    <span style={{ fontSize: '13px' }} className={cx('tippy-content')}>
                      Tài khoản
                    </span>
                  }
                >
                  <div
                    onClick={() => handleClickActive('account')}
                    className={cx('icons', activeProfile && 'active')}
                  >
                    <UserIcon className={cx('icon')} />
                    <UserActiveIcon className={cx('icon-active')} />
                  </div>
                </Tippy>
              </HeadlessTippy>
            </div>
            <div className={cx('wishlist')}>
              <Tippy
                placement="bottom"
                delay={100}
                content={
                  <span style={{ fontSize: '13px' }} className={cx('tippy-content')}>
                    Yêu thích
                  </span>
                }
              >
                <div
                  onClick={() => handleClickActive('wishlist')}
                  className={cx('icons', activeWishList && 'active')}
                >
                  <HeartIcon className={cx('icon')} />
                  <HeartActiveIcon className={cx('icon-active')} />
                </div>
              </Tippy>
              <WishList openWishList={activeWishList} onClose={onCloseWithList} />
            </div>
            <Link onClick={() => handleClickActive('cart')} to={routes.cart} className={cx('cart')}>
              <Tippy
                placement="bottom"
                delay={100}
                content={
                  <span style={{ fontSize: '13px' }} className={cx('tippy-content')}>
                    Giỏ hàng
                  </span>
                }
              >
                <div className={cx('icons', location.pathname === routes.cart && 'active')}>
                  <BagIcon className={cx('icon')} />
                  <BagActiveIcon className={cx('icon-active')} />
                  {cart?.cartItems && cart.cartItems.length > 0 && (
                    <span
                      className={cx('quantity-item', location.pathname === routes.cart && 'active')}
                    >
                      {cart.cartItems.length}
                    </span>
                  )}
                </div>
              </Tippy>
            </Link>
          </div>
        </div>
      </header>
    </div>
  );
}

export default memo(Header);


