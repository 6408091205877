import { combineReducers } from '@reduxjs/toolkit';
import { appReducer, authReducer, cartReducer } from '../slices';

const rootReducer = combineReducers({
  auth: authReducer,
  cart: cartReducer,
  app: appReducer,
  // product: productReducer,
});

export default rootReducer;
