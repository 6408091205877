import { routes } from '@/config';
import { v4 as uuidv4 } from 'uuid';
export interface ISubLink {
  title: string;
  _id: string;
  to?: string;
  links: ILink[];
}
export interface ILink {
  content: string;
  _id: string;
  to: string;
}
export interface IHeaderLink {
  to: string;
  title: string;
  subLinks: ISubLink[];
  class?: string;
}
export const headerLinks: IHeaderLink[] = [];
