import React, { ReactNode, memo } from 'react';
import { routes } from '@/config';
import { Button } from '@/components/shares';
type Props = {
  icon: ReactNode;
  titleBtn: string;
  content1: string;
  content2?: string;
  iconBtn?: ReactNode;
  noPadding?: boolean;
  onClickButton?: () => void;
};
const EmptyContent: React.FC<Props> = ({
  icon,
  titleBtn,
  content1,
  content2,
  iconBtn,
  noPadding,
  onClickButton
}) => {
  return (
    <div className='flex flex-col gap-4 items-center mb-12'>
      <div className=''>{icon}</div>
      <div>
        <div className='mt-12 mx-4 font-bold text-2xl text-center'>{content1}</div>
        <p className="m-0">{content2}</p>
        {titleBtn && (
          <Button
            to={
              titleBtn.toLowerCase().includes('dạo một vòng') ? routes.newArrival : undefined
            }
            className='mt-12 w-full'
            leftIcon={iconBtn}
            primary
            onClick={() => onClickButton && onClickButton()}
          >
            {titleBtn}
          </Button>

        )}
      </div>
    </div>
  );
};

export default memo(EmptyContent);
