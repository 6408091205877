import { productService } from '@/services';
import { handleErrorHooks } from '@/utils';
import { useCallback, useState } from 'react';
import { useSWRConfig } from 'swr';
import { ErrCallbackType } from '../types';

export function useFavorite() {
  const { mutate } = useSWRConfig();
  const [loading, setLoading] = useState(false);
  const handleRemoveFavorite = useCallback(
    async (
      productId: string,
      successCallback: () => void,
      errCallback?: ErrCallbackType,
      isMultiple = false,
    ) => {
      try {
        setLoading(true);
        await productService.favorite(productId, 'remove');
        !isMultiple && mutate('GET_PROFILE');
        successCallback();
      } catch (error) {
        handleErrorHooks(error, errCallback);
      } finally {
        setLoading(false);
      }
    },
    [mutate],
  );
  const handleAddFavorite = useCallback(
    async (productId: string, successCallback: () => void, errCallback?: ErrCallbackType) => {
      try {
        setLoading(true);
        await productService.favorite(productId, 'add');
        mutate('GET_PROFILE');
        successCallback();
      } catch (error) {
        handleErrorHooks(error, errCallback);
      } finally {
        setLoading(false);
      }
    },
    [mutate],
  );

  return {
    loading,
    onRemoveFavorite: handleRemoveFavorite,
    onAddFavorite: handleAddFavorite,
  };
}
